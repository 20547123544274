import { render, staticRenderFns } from "./najimniy-garnitur-dublin35-chorniy.vue?vue&type=template&id=2aea4ce7&"
import script from "./najimniy-garnitur-dublin35-chorniy.vue?vue&type=script&lang=js&"
export * from "./najimniy-garnitur-dublin35-chorniy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports