var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('g',[_c('svg:style',[_vm._v(" .handle_cover, .handle_grip { stroke: #000; stroke-miterlimit: 10; stroke-width: 0.25px; } .handle_cover, .handle_grip { fill: #000; } .handle_shadow, .door_lock_shadow { mix-blend-mode: multiply; } .handle_shadow { opacity: 0.35; } .keyhole { fill: #424242; } .door_lock_shadow { opacity: 0.6; mask: url(#mask); } .door_lock_shadowLeft { opacity: 0.6; mask: url(#maskLeft); } .keyhole_shadow { filter: url(#keyhole_shadow_mask); } .keyhole_shadowLeft { filter: url(#keyhole_shadow_mask); } ")]),_c('linearGradient',{attrs:{"id":"handle-gradient","x1":"0","y1":"0","x2":"100%","y2":"0"}},[_c('stop',{attrs:{"offset":"0.01","stop-color":"#babbbc"}}),_c('stop',{attrs:{"offset":"0.29","stop-color":"#97999d"}}),_c('stop',{attrs:{"offset":"0.65","stop-color":"#bdbdc1"}}),_c('stop',{attrs:{"offset":"0.87","stop-color":"#9d9fa2"}}),_c('stop',{attrs:{"offset":"1","stop-color":"#a6a7aa"}}),_c('stop',{attrs:{"offset":"1","stop-color":"#c2c5c9"}})],1),(
      (_vm.doorMirrorType !== 'mirrored' && _vm.viewType.id === '3') ||
        (_vm.doorMirrorType !== 'mirrored' && _vm.viewType.id === '5') ||
        (_vm.doorMirrorType === 'mirrored' && _vm.viewType.id === '4') ||
        (_vm.doorMirrorType === 'mirrored' && _vm.viewType.id === '6')
    )?_c('g',[_c('polyline',{staticClass:"handle_cover",attrs:{"points":("\n        " + (_vm.handleX1 + 19.66) + " " + (_vm.handleY1 + 144.96) + "\n        " + (_vm.handleX1 + 19.66) + " " + (_vm.handleY1 + 134.11) + "\n        " + (_vm.handleX1 + 14.07) + " " + (_vm.handleY1 + 134.11) + "\n        " + (_vm.handleX1 + 14.07) + " " + (_vm.handleY1 + 168.41) + "\n        " + (_vm.handleX1 + 19.66) + " " + (_vm.handleY1 + 168.41) + "\n        " + (_vm.handleX1 + 19.66) + " " + (_vm.handleY1 + 148.46) + "\n      ")}}),_c('g',[_c('image',{staticClass:"handle_shadow",attrs:{"width":"25","height":"11","x":_vm.handleX1 - 4.13,"y":_vm.handleY1 + 143.13,"xlink:href":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAALCAYAAACK9ybzAAAACXBIWXMAAAsSAAALEgHS3X78AAAAoElEQVQ4T73TsQ2DMBCFYUJB6cKiySTMkDQwDutAjcg8mSBCmQClMP9ZD5TaCUb6XGDdO53BRQihMDwXKf8gZh3ZamAbFRw86h945VheqfzY1V5c0eCGFl2CVvWN8irlx8Vpo8eIGY8Es+p75bm9iR2VjXjHgCcWvBMsqh+U55UfFztLG3XCCys+CVbVT8qrv5ucPkmWb3L+35XlnuS48Ru/F4J+ZPkKegAAAABJRU5ErkJggg=="}}),_c('rect',{staticClass:"handle_grip",attrs:{"x":_vm.handleX1 - 1.2,"y":_vm.handleY1 + 144.96,"width":"18.18","height":"3.5"}})]),_c('g',{staticClass:"door_lock_shadow"},[_c('path',{attrs:{"d":("\n          M" + (_vm.handleX1 + 18.1) + "," + (_vm.handleY1 + 157.08) + "\n          a1.39,1.39,0,1,0-2.06,1.21\n          v1.61\n          h0\n          a0.68,0.68,0,0,0,1.36,0\n          v-1.62\n          A1.38,1.38,0,0,0," + (_vm.handleX1 + 18.1) + "," + (_vm.handleY1 + 157.08) + "\n          Z\n        ")}})]),_c('rect',{staticClass:"keyhole",attrs:{"x":_vm.handleX1 + 15.1,"y":_vm.handleY1 + 156.9,"width":"1.5","height":"0.4","rx":"0.18","ry":"0.18"}})]):_vm._e(),(
      (_vm.doorMirrorType !== 'mirrored' && _vm.viewType.id === '4') ||
        (_vm.doorMirrorType !== 'mirrored' && _vm.viewType.id === '6') ||
        (_vm.doorMirrorType === 'mirrored' && _vm.viewType.id === '3') ||
        (_vm.doorMirrorType === 'mirrored' && _vm.viewType.id === '5')
    )?_c('g',[_c('polyline',{staticClass:"handle_cover",attrs:{"points":("\n        " + (_vm.handleX1 + 15.66) + " " + (_vm.handleY1 + 144.96) + "\n        " + (_vm.handleX1 + 15.66) + " " + (_vm.handleY1 + 134.11) + "\n        " + (_vm.handleX1 + 10.07) + " " + (_vm.handleY1 + 134.11) + "\n        " + (_vm.handleX1 + 10.07) + " " + (_vm.handleY1 + 168.41) + "\n        " + (_vm.handleX1 + 15.66) + " " + (_vm.handleY1 + 168.41) + "\n        " + (_vm.handleX1 + 15.66) + " " + (_vm.handleY1 + 148.46) + "\n      ")}}),_c('g',[_c('image',{staticClass:"handle_shadow",attrs:{"width":"25","height":"11","x":_vm.handleX1 + 8.13,"y":_vm.handleY1 + 143.13,"xlink:href":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAALCAYAAACK9ybzAAAACXBIWXMAAAsSAAALEgHS3X78AAAAoElEQVQ4T73TsQ2DMBCFYUJB6cKiySTMkDQwDutAjcg8mSBCmQClMP9ZD5TaCUb6XGDdO53BRQihMDwXKf8gZh3ZamAbFRw86h945VheqfzY1V5c0eCGFl2CVvWN8irlx8Vpo8eIGY8Es+p75bm9iR2VjXjHgCcWvBMsqh+U55UfFztLG3XCCys+CVbVT8qrv5ucPkmWb3L+35XlnuS48Ru/F4J+ZPkKegAAAABJRU5ErkJggg=="}}),_c('rect',{staticClass:"handle_grip",attrs:{"x":_vm.handleX1 + 11.48,"y":_vm.handleY1 + 144.96,"width":"18.18","height":"3.5"}})]),_c('g',{staticClass:"door_lock_shadow"},[_c('path',{attrs:{"d":("\n          M" + (_vm.handleX1 + 14.12) + "," + (_vm.handleY1 + 157.08) + "\n          a1.39,1.39,0,1,0-2.06,1.21\n          v1.61\n          h0\n          a0.68,0.68,0,0,0,1.36,0\n          v-1.62\n          A1.38,1.38,0,0,0," + (_vm.handleX1 + 14.12) + "," + (_vm.handleY1 + 157.08) + "\n          Z\n        ")}})]),_c('rect',{staticClass:"keyhole",attrs:{"x":_vm.handleX1 + 12.1,"y":_vm.handleY1 + 156.9,"width":"1.5","height":"0.4","rx":"0.18","ry":"0.18"}})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }